<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    provide() {
        return {
            // 获取baseUrl
            getBaseUrl: this.getBaseUrl,
            // 获取图片路径
            getBaseImgUrl: this.getBaseImgUrl,
        }
    },
    methods: {
        // 获取baseUrl
        getBaseUrl() {
            return process.env.VUE_APP_API_URL;
        },
        // 获取图片路径
        getBaseImgUrl(imgId) {
            return this.getBaseUrl() + "/showImage?id=" + imgId;
        },
    }
}
</script>

<style lang="scss">
#app {
    min-width: 1280px;
    height: 100%;
    // filter: grayscale(100%);
}
</style>
