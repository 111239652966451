<template>
    <div style="height: 100%">
        <!-- 头部菜单 -->
        <header-menu></header-menu>

        <!-- 主体部分 -->
        <div class="main-body">
            <router-view></router-view>
        </div>

        <!-- footer部分 -->
        <footer-bottom></footer-bottom>

        <!-- 右下角悬浮工具 -->
        <fixed-tools></fixed-tools>
        
    </div>
</template>

<script>
import headerMenu from "@/components/home/headerMenu.vue";
import footerBottom from "@/components/home/footerBottom.vue";
import fixedTools from "@/components/home/fixedTools.vue";

export default {
    components: {
        headerMenu,
        footerBottom,
        fixedTools,
    },
}
</script>

<style lang="scss" scoped>
.main-body {
    padding-top: 70px; // 头部高度
    min-height: calc(100% - 259px);
}
</style>