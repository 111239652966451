<template>
    <div class="header-container">
        <!-- 官网logo -->
        <router-link to="/">
            <div class="logo-wrap">
                <img src="~@/assets/images/logo3.png" height="50px">
                <!-- <span>南京昆虫软件</span> -->
            </div>
        </router-link>
        <!-- 菜单+子菜单 -->
        <div class="menu-wrap">
            <div class="menu-item" :class="{'active': $route.path.indexOf(`/${menu.pathName}`) === 0}" v-for="menu in menuList" :key="menu.pathName">
                <div class="title">{{ menu.name }}</div>
                <!-- 子菜单 -->
                <div class="sub-menu-box">
                    <!-- 子菜单：左侧介绍 -->
                    <div class="left-intro">
                        <div class="head"><i :class="['iconfont', menu.icon]"></i>{{ menu.name }}</div>
                        <div class="intro">{{ menu.intro }}</div>
                    </div>
                    <!-- 子菜单：右侧菜单 -->
                    <div class="right-wrap">
                        <!-- 子菜单：菜单分类 -->
                        <template v-if="menu.category">
                            <div class="cate-wrap" v-for="(cate, index) in menu.category" :key="menu.name + index">
                                <div class="header-line">{{ cate.cateName }}</div>
                                <!-- 子菜单盒子 -->
                                <div :class="{'sub-menus': true, 'flex': menu.pathName != 'casesDetail'}">
                                    <!-- 子菜单：每项 -->
                                    <template v-if="menu.pathName == 'productDetail'">
                                        <router-link :to="{ name: menu.pathName, params: { id: item.pathName } }" class="sub-menu-item"
                                            v-for="item in cate.children" :key="item.pathName">
                                            <img :src="item.imgSrc" alt="">
                                            <div>
                                                <div class="title ell">
                                                    {{ item.title }}
                                                    <i class="iconfont icon-right-arrow2"></i>
                                                </div>
                                                <div class="subtitle">{{ item.subtitle }}</div>
                                            </div>
                                        </router-link>
                                    </template>
                                    <!-- 子菜单：锚点跳转 -->
                                    <template v-else>
                                        <!-- {path: `/${menu.pathName}`, query: {id: item.pathName}} -->
                                        <router-link :to="{path: `/${menu.pathName}`, query: {id: item.pathName}}" class="sub-menu-item"
                                            v-for="(item, index) in cate.children" :key="item.pathName + index">
                                            <img :src="item.imgSrc" alt="">
                                            <div>
                                                <div class="title ell">
                                                    {{ item.title }}
                                                    <i class="iconfont icon-right-arrow2"></i>
                                                </div>
                                                <div class="subtitle">{{ item.subtitle }}</div>
                                            </div>
                                        </router-link>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <!-- 子菜单：没有分类 -->
                        <template>
                            <div class="sub-menus flex">
                                <!-- 子菜单：每项 -->
                                <router-link :to="{path: `/${menu.pathName}`, query: {id: item.pathName}}" class="sub-menu-item"
                                    v-for="item in menu.children" :key="item.pathName">
                                    <img :src="item.imgSrc" alt="">
                                    <div>
                                        <div class="title ell">
                                            {{ item.title }}
                                            <i class="iconfont icon-right-arrow2"></i>
                                        </div>
                                        <div class="subtitle">{{ item.subtitle }}</div>
                                    </div>
                                </router-link>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import menuList from "@/jsData/home/headerMenu";

export default {
    name: "headerMenu",
    data() {
        return {
            menuList: []
        }
    },
    created() {
        this.menuList = menuList;
    },
}
</script>

<style lang="scss" scoped>
.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100%);
    min-width: 1280px;
    height: 70px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15%;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
    // logo部分
    .logo-wrap {
        cursor: pointer;
        span {
            color: #3466a7;
            font-size: 22px;
            font-weight: 600;
            vertical-align: 8px;
            margin-left: 7px;
        }
        >img {
            margin-top: 7px;
        }
    }
    // 菜单部分
    .menu-wrap {
        display: flex;
        justify-content: space-around;
        width: 560px;
        height: 70px;
        line-height: 70px;
        .menu-item {
            position: relative;
            width: 100px;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            .title {
                font-size: 16px;
                height: 100%;
                text-align: center;
                cursor: pointer;
            }
            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                content: "";
                display: inline-block;
                width: 0;
                height: 4px;
                // border-radius: 4px 4px 0 0;
                background-color: $theme-color;
                transition: all 0.3s;
                -webkit-transition: all 0.3s;
            }
            &:hover {
                &::after {
                    width: 80px;
                }
                .sub-menu-box {
                    opacity: 1;
                    transform: scaleY(1);
                    transition: 0.2s;
                    -webkit-transition: 0.2s;
                }
            }
            &.active {
                &::after {
                    width: 80px;
                }
                .title {
                    font-weight: 600;
                }
            }
            // 子菜单
            .sub-menu-box {
                display: flex;
                justify-content: space-between;
                position: fixed;
                top: 70px;
                left: 0;
                right: 0;
                min-width: 1280px;
                min-height: 300px;
                z-index: 99;
                padding: 20px 15% 5px 15%;
                border-top: 1px solid #f0f4f8;
                box-shadow: 0 15px 24px 0 rgba(11, 33, 55, 0.08);
                background-color: #ffffff;
                opacity: 0;
                transform: scaleY(0);
                transform-origin: 50% 0;
                // 子菜单：左侧介绍
                .left-intro {
                    width: 240px;
                    margin-right: 80px;
                    padding-top: 10px;
                    background-image: url("@/assets/images/menus/intro-bg.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: 0% 85%;
                    .head {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 28px;
                        margin-bottom: 10px;
                        > i {
                            font-size: 20px;
                            color: $theme-color;
                            font-weight: 400;
                            vertical-align: -2px;
                            margin-right: 5px;
                        }
                    }
                    .intro {
                        line-height: 24px;
                        color: $text-sub-color;
                    }
                }
                // 子菜单：右侧菜单
                .right-wrap {
                    flex: 1;
                    // 子菜单：菜单分类
                    .cate-wrap {
                        margin-top: 10px;
                        margin-bottom: 20px;
                        .header-line {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 30px;
                            border-bottom: 1px solid $background-under;
                            padding-bottom: 7px;
                        }
                    }
                    // 子菜单盒子
                    .sub-menus {
                        width: 100%;
                        margin-top: 8px;
                        &.flex {
                            display: flex;
                            flex-flow: row wrap;
                            .sub-menu-item {
                                width: calc(100% / 2 - 30px);
                            }
                        }
                        .sub-menu-item {
                            width: 100%;
                            display: flex;
                            flex-flow: row nowrap;
                            margin: 10px 30px 30px 0;
                            transition: all 0.3s;
                            -webkit-transition: all 0.3s;
                            cursor: pointer;
                            > img {
                                width: 46px;
                                height: 46px;
                                margin-top: 5px;
                                margin-right: 10px;
                            }
                            > div {
                                width: calc(100% - 50px);
                                .title {
                                    width: 100%;
                                    height: auto;
                                    font-size: 15px;
                                    margin-bottom: 2px;
                                    font-weight: 600;
                                    line-height: 22px;
                                    text-align: left;
                                    > i {
                                        position: absolute;
                                        top: 1px;
                                        margin-left: 4px;
                                        color: $theme-hover;
                                        opacity: 0;
                                        transform: translateX(-8px);
                                        transition: opacity 0.3s ease,
                                            transform 0.3s ease;
                                        -webkit-transition: opacity 0.3s ease,
                                            transform 0.3s ease;
                                    }
                                }
                                .subtitle {
                                    font-size: 14px;
                                    line-height: 22px;
                                    height: auto;
                                    text-align: left;
                                    color: $text-sub-color;
                                    display: -webkit-box; // 将盒子转换为弹性盒子
                                    -webkit-box-orient: vertical; // 文本显示方式，默认水平
                                    -webkit-line-clamp: 2; // 设置显示多少行
                                    overflow: hidden;
                                }
                            }
                            &:hover {
                                transform: translateY(-3px);
                                .title {
                                    color: $theme-hover;
                                    > i {
                                        transform: translateX(0);
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>