<template>
    <div class="footer-container">
        <div class="footer-body">
            <!-- 左侧导航内容 -->
            <div class="left-wrap">
                <!-- 导航分类 -->
                <div class="nav-cate" v-for="cate in menuList" :key="cate.pathName" :style="{flex: cate.pathName == 'casesDetail' ? 3 : 2}">
                    <!-- 标题 -->
                    <div class="title">{{ cate.cateName }}</div>
                    <!-- 导航每项 -->
                    <template v-if="cate.pathName == 'productDetail'">
                        <router-link :to="{ name: cate.pathName, params: { id: menu.pathName } }" class="nav-item" v-for="(menu, index) in cate.children" :key="menu.pathName + index">
                            <img :src="menu.imgSrc" alt="">
                            <div class="ell">{{ menu.title }}</div>
                        </router-link>
                    </template>
                    <template v-else>
                        <router-link :to="{path: `/${cate.pathName}`, query: {id: menu.pathName}}" class="nav-item" v-for="(menu, index) in cate.children" :key="menu.pathName + index">
                            <img :src="menu.imgSrc" alt="">
                            <div class="ell">{{ menu.title }}</div>
                        </router-link>
                    </template>
                </div>
            </div>
            <!-- 右侧二维码 -->
            <div class="right-wrap">
                <el-image :src="require('@/assets/images/home/code-class.jpg')" :preview-src-list="[require('@/assets/images/home/code-class.jpg')]"></el-image>
                <!-- <img src="~@/assets/images/home/code-class.png" alt=""> -->
                <span class="intro-text">关注昆虫小课堂公众号</span>
                <span class="intro-text">第一时间获取最新资讯</span>
            </div>
        </div>
        <!-- 版权所有 -->
        <div class="copyright">技术支持 Email:support@kcidea.com <span @click="beianLink">备案号：苏ICP备11031502号-1</span> Copyright © 2023.南京昆虫软件有限公司, All rights reserved.</div>
    </div>
</template>

<script>
import menuList from "@/jsData/home/footerMenu"

export default {
    name: "footerBottom",
    data() {
        return {
            menuList: []
        }
    },
    created() {
        this.menuList = menuList;
    },
    methods: {
        beianLink() {
            window.open("https://beian.miit.gov.cn/", "_blank");
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-container {
    width: 100%;
    padding: 0 15%;
    color: #C3C4C7;
    background-color: #0E1421;
    .footer-body {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 34px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .left-wrap {
            width: 80%;
            display: flex;
            .nav-cate {
                flex: 2;
                margin-right: 20px;
                .title {
                    font-size: 15px;
                    line-height: 2;
                    font-weight: 600;
                    color: #868A91;
                }
                .nav-item {
                    display: flex;
                    align-items: center;
                    margin: 12px 0;
                    color: #C3C4C7;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;
                    &:hover {
                        // color: $theme-hover;
                        transform: translateY(-2px);
                        > img {
                            transform: scale(1.06);
                        }
                    }
                    > img {
                        width: 17px;
                        margin-right: 7px;
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                    }
                }
            }
        }
        .right-wrap {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            > .el-image {
                width: 90px;
                margin-bottom: 8px;
            }
            .intro-text {
                line-height: 1.5;
            }
        }
    }
    .copyright {
        height: 50px;
        line-height: 50px;
        text-align: center;
        span {
            cursor: pointer;
            color: #6ea6ff;
        }
    }
}
</style>