export default [
    // 产品
    {
        name: "产品",
        pathName: "productDetail",
        intro: "昆虫软件基于多年数据及用户需求的积累，打造的一系列针对高校资源建设解决方案的产品。是国内近两百家高校图书馆的共同选择。",
        icon: "icon-product",
        category: [
            {
                cateName: "高校资源采购",
                children: [
                    {
                        pathName: "ers",
                        title: "电子资源利用绩效分析平台(ERS)",
                        subtitle: "为图书馆资源采购、学科馆员及管理决策提供决策支持",
                        imgSrc: "/images/menus/icon-menu1.png"
                    },
                    {
                        pathName: "erm",
                        title: "电子资源管理系统(ERM)",
                        subtitle: "辅助图书馆资源采购以及资源管理等工作，提高工作效率。",
                        imgSrc: "/images/menus/icon-menu2.png"
                    },
                    {
                        pathName: "bookIntelligent",
                        title: "外文学术图书智能采选平台",
                        subtitle: "精准定位至学科层级的实际需求，对高校资源采购流程化繁为简，为高校量身定制出一套采选方案。",
                        imgSrc: "/images/menus/icon-menu3.png"
                    },
                ]
            },
            {
                cateName: "高校资源导航，学术成果聚合",
                children: [
                    {
                        pathName: "resourceMap",
                        title: "学术资源地图",
                        subtitle: "基于学科聚类和数据驱动的多维度学科资源导航",
                        imgSrc: "/images/menus/icon-menu4.png"
                    },
                    {
                        pathName: "science",
                        title: "数据科学平台",
                        subtitle: "利用人工智能算法引擎，对学术成果数据进行分类。",
                        imgSrc: "/images/menus/icon-menu8.png"
                    },
                ]
            },
        ],
    },
    // 客户案例
    {
        name: "客户案例",
        pathName: "casesDetail",
        intro: "基于昆虫多年数据与需求积累，成功为国内众多高校图书馆解决难题",
        icon: "icon-cases",
        category: [
            {
                cateName: "机构知识库",
                children: [
                    {
                        pathName: "institutional",
                        title: "上海交通大学机构知识管理服务系统",
                        subtitle: "汇聚10个主流数据库，采集59万+元数据,利用算法匹配到二级机构（70+）和学者个人（1万+），大部分可通过DOI链接至全文，部分金色OA可直接下载全文。真正实现了交大主要学术成果统一管理、一站式检索利用。",
                        imgSrc: "/images/menus/icon-menu6.png"
                    },
                ]
            },
            {
                cateName: "学科聚类分析",
                children: [
                    {
                        pathName: "eResource",
                        title: "清华大学图书馆--电子期刊导航系统",
                        subtitle: "如何将外文学术期刊精准映射到教育部学科分类，这是很多大学图书馆在向学者提供资源服务时遇到的难点。通过数据科学平台提供的API接口，获取教育部学科分类包。清华大学图书馆成功构建学科资源导航。",
                        imgSrc: "/images/menus/icon-menu7.png"
                    },
                    {
                        pathName: "resourceMap",
                        title: "学术资源地图",
                        subtitle: "快速为用户揭示本校馆藏的高质量资源，并将本校订购的多种资源聚合导航。",
                        imgSrc: "/images/menus/icon-menu5.png"
                    },
                ]
            },
        ],
    },
    // 资源中心
    {
        name: "资源中心",
        pathName: "resourceDetail",
        intro: "基于产品多年数据积累与高校开放数据，构建学术资源画像模型，助力高校图书馆建设。",
        icon: "icon-resource",
        category: [
            // {
            //     cateName: "学术资源排名",
            //     children: [
            //         {
            //             pathName: "internationalAcademic",
            //             title: "国际学术数据库排行榜",
            //             subtitle: "综合各数据库资源利用与学科价值数据，构建数据库评估体系",
            //             imgSrc: "/images/menus/icon-menu9.png"
            //         },
            //         {
            //             pathName: "disciplineConstruction",
            //             title: "高校学科建设排行榜",
            //             subtitle: "综合国内高校学科资源订购利用数据，构建按区域、按类型的学科建设排行。",
            //             imgSrc: "/images/menus/icon-menu10.png"
            //         },
            //     ]
            // },
            {
                cateName: "开放数据",
                children: [
                    {
                        pathName: "basicData",
                        title: "基础数据",
                        subtitle: "全国普通高等院校数据、本科专业目录数据、学科目录数据",
                        imgSrc: "/images/menus/icon-menu11.png"
                    },
                    {
                        pathName: "talentCultivation",
                        title: "人才培育",
                        subtitle: "全国普通高校学科竞赛排行榜数据",
                        imgSrc: "/images/menus/icon-menu12.png"
                    },
                    {
                        pathName: "teachingStaff",
                        title: "师资队伍",
                        subtitle: "中国工程院院士数据、中国科学院院士数据",
                        imgSrc: "/images/menus/icon-menu13.png"
                    },
                    {
                        pathName: "keyProjects",
                        title: "重点工程",
                        subtitle: "第一轮双一流建设高校数据、第一轮双一流建设学科数据、985工程数据、211工程数据、第二轮双一流建设高校数据、第二轮双一流建设学科数据",
                        imgSrc: "/images/menus/icon-menu17.png"
                    },
                ]
            },
        ],
    },
    // 关于我们
    {
        name: "关于我们",
        pathName: "aboutus",
        intro: "昆虫软件是一家专业为高等院校图情单位提供第三方数据及软件服务的公司，是一家专业的数据、信息及软件服务提供商。",
        icon: "icon-aboutus",
        children: [
            {
                pathName: "companyProfile",
                title: "公司简介",
                subtitle: "昆虫发展历程及简介、公司核心价值观",
                imgSrc: "/images/menus/icon-menu14.png"
            },
            {
                pathName: "joinUs",
                title: "诚聘英才",
                subtitle: "加入昆虫软件成为我们的一员",
                imgSrc: "/images/menus/icon-menu18.png"
            },
            {
                pathName: "newsInformation",
                title: "新闻动态",
                subtitle: "昆虫最新消息，及时公布我们的进展",
                imgSrc: "/images/menus/icon-menu15.png"
            },
            {
                pathName: "contactus",
                title: "联系我们",
                subtitle: "欢迎咨询公司产品相关服务与需求",
                imgSrc: "/images/menus/icon-menu16.png"
            },
        ]
    },
]