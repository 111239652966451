import Vue from 'vue'
import Vuex from 'vuex'

//vuex加密
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate"

var ls = new SecureLS({
    encodingType: "aes",
    isCompression: false,
    encryptionSecret: "secret-store"
});

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: "store",
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            paths: []
        })
    ],
    strict: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
