export default [
    {
        cateName: "核心产品",
        pathName: "productDetail",
        children: [
            {
                title: "电子资源利用绩效分析平台",
                pathName: "ers",
                imgSrc: "/images/menus/icon-menu1.png"
            },
            {
                title: "资源地图",
                pathName: "resourceMap",
                imgSrc: "/images/menus/icon-menu4.png"
            },
            {
                title: "数据科学平台",
                pathName: "science",
                imgSrc: "/images/menus/icon-menu8.png"
            },
        ]
    },
    {
        cateName: "客户案例",
        pathName: "casesDetail",
        children: [
            {
                title: "上海交通大学机构知识管理服务系统",
                pathName: "institutional",
                imgSrc: "/images/menus/icon-menu6.png"
            },
            {
                title: "清华大学图书馆--电子期刊导航系统",
                pathName: "eResource",
                imgSrc: "/images/menus/icon-menu7.png"
            },
            {
                title: "学术资源地图",
                pathName: "resourceMap",
                imgSrc: "/images/menus/icon-menu5.png"
            },
        ]
    },
    {
        cateName: "资源中心",
        pathName: "resourceDetail",
        children: [
            // {
            //     title: "国际学术数据库排行榜",
            //     pathName: "internationalAcademic",
            //     imgSrc: "/images/menus/icon-menu9.png"
            // },
            // {
            //     title: "高校学科建设排行榜",
            //     pathName: "disciplineConstruction",
            //     imgSrc: "/images/menus/icon-menu10.png"
            // },
            {
                title: "开放数据",
                pathName: "openData",
                imgSrc: "/images/menus/icon-menu12.png"
            },
        ]
    },
    {
        cateName: "关于我们",
        pathName: "aboutus",
        children: [
            {
                title: "公司简介",
                pathName: "companyProfile",
                imgSrc: "/images/menus/icon-menu14.png"
            },
            {
                title: "诚聘英才",
                pathName: "joinUs",
                imgSrc: "/images/menus/icon-menu18.png"
            },
            {
                title: "新闻动态",
                pathName: "newsInformation",
                imgSrc: "/images/menus/icon-menu15.png"
            },
            {
                title: "联系我们",
                pathName: "contactus",
                imgSrc: "/images/menus/icon-menu16.png"
            },
        ]
    },
]