import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    // 登录页
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    // 主页
    {
        path: '/',
        name: 'home',
        redirect: "/index",
        component: HomeView,
        children: [
            // 首页
            {
                path: "/index",
                name: "index",
                component: () => import('../views/home/index.vue')
            },
            // 产品 -> 详情页
            {
                path: "/productDetail/:id",
                name: "productDetail",
                component: () => import('../views/products/productDetail.vue')
            },
            // 客户案例 -> 详情页
            {
                path: "/casesDetail",
                name: "casesDetail",
                component: () => import('../views/cases/casesDetail.vue')
            },
            // 资源中心 -> 详情页
            {
                path: "/resourceDetail",
                name: "resourceDetail",
                component: () => import('../views/resource/resourceDetail.vue')
            },
            // 关于我们 -> 详情页
            {
                path: "/aboutus",
                name: "aboutus",
                component: () => import('../views/aboutus/index.vue')
            },
            // 发票页
            {
                path: "/fp",
                name: "fp",
                component: () => import('../views/fp/index.vue')
            },
        ]
    },
    // 申请免费试用
    {
        path: '/applyForm',
        name: 'applyForm',
        component: () => import('../views/applyForm/index.vue')
    },
]

// 修改原型对象中的push函数
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes,
    // 路由跳转时页面滚动位置
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router
