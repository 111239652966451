import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ui库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 挂载axios
import Axios from "axios";
Vue.prototype.$axios = Axios;

import scroll from 'vue-seamless-scroll';
Vue.use(scroll)

import "@/assets/scss/global.scss";
import "@/assets/scss/public.scss";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
