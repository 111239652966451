<template>
    <!-- 右下角固定工具 -->
    <div class="tool-wrap">
        <div class="tool-list">
            <!-- 联系我们 -->
            <router-link :to="{path: '/aboutus', query: {id: 'contactus'}}" class="item">
                <i class="iconfont icon-contact"></i>
            </router-link>
            <!-- 昆虫二维码 -->
            <el-popover placement="left" width="200" trigger="hover" class="item">
                <div>
                    <el-image :src="require('@/assets/images/home/code-class.jpg')" :preview-src-list="[require('@/assets/images/home/code-class.jpg')]"></el-image>
                    <div style="text-align: center;">关注昆虫小课堂公众号<br />第一时间获取最新资讯</div>
                </div>
                <i slot="reference" class="iconfont icon-qrcode"></i>
            </el-popover>
        </div>
        <!-- 回到顶部 -->
        <div class="back-top" @click="handleBackTop"><i class="iconfont icon-backtop"></i></div>
    </div>
</template>

<script>
export default {
    name: "fixedTools",
    mounted() {
        // 滚动出现“返回顶部”图标
        let backDom = document.querySelector(".back-top");
        document.body.onscroll = (event) => {
            if (document.documentElement.scrollTop > 100) {
                backDom.classList.add('active');
            } else {
                backDom.classList.remove('active');
            }
        }
    },
    beforeDestroy() {
        document.body.onscroll = null;
    },
    methods: {
        // 点击返回顶部
        handleBackTop() {
            // let scrollTop = document.documentElement.scrollTop;
            // let timer = setInterval(() => {
            //     scrollTop -= scrollTop / 10;
            //     window.scrollTo(0, scrollTop);
            //     // 当 scrollTop 小于 10 时，直接使 scrollTop 为 0，并且清空定时器。
            //     if (scrollTop < 10) {
            //         window.scrollTo(0, 0);
            //         clearInterval(timer);
            //     }
            // }, 15);

            document.documentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.tool-wrap {
    position: fixed;
    right: 20px;
    bottom: 50px;
    z-index: 99;
    .tool-list {
        width: 46px;
        padding: 18px 11px;
        background: #fff;
        box-shadow: 0 0 15px 0 rgba($color: $text-sub-color, $alpha: 0.5);
        border-radius: 27px;
        display: flex;
        flex-flow: column nowrap;
        .iconfont {
            font-size: 24px;
            cursor: pointer;
            &:hover {
                color: $theme-color;
            }
        }
        .item:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    .back-top {
        width: 46px;
        text-align: center;
        padding-top: 12px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 15px 0 rgba($color: $text-sub-color, $alpha: 0.5);
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        height: 0;
        margin-top: 10px;
        transition: all 0.5s ease-in-out 0.3s;
        -webkit-transition: all 0.5s ease-in-out 0.3s;
        &:hover {
            > i {
                color: $theme-color;
            }
        }
        > i {
            font-size: 22px;
            color: $text-sub-color;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-in 0.3s, visibility 0.3s ease-in 0.3s;
            -webkit-transition: opacity 0.3s ease-in 0.3s,
                visibility 0.3s ease-in 0.3s;
        }
        &.active {
            height: 46px;
            visibility: visible;
            opacity: 1;
            > i {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>